// 数据报表模块
import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
import {
  MteSearch
} from './model/reportModel';

export default {
  /**
   * @description: MTE报表
   * @param {}
   * @return {array}
   */
  'POST/mte_report/mte_report': (params: MteSearch, extra?: Extra) => {
    return axios(`/mte_report/mte_report`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 预算维护_预算记录查询
   * @param {}
   * @return {array}
   */
  'GET/budget/{id}/modify_record': (params: any, extra?: Extra) => {
    return axios(`/budget/${params.id}/modify_record`, preParams({}, extra, 'get'))
  },
  /**
   * @description: 加班报表_类型下拉框
   * @param {}
   * @return {array}
   */
  'GET/work_overtime/type_drop_list': (params: any, extra?: Extra) => {
    return axios(`/work_overtime/type_drop_list`, preParams({}, extra, 'get'))
  },
  /**
   * @description: 加班报表_报表
   * @param {}
   * @return {array}
   */
  'POST/work_overtime/report': (params: any, extra?: Extra) => {
    return axios(`/work_overtime/report`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 加班报表_用户加班时长分页查询
   * @param {}
   * @return {array}
   */
  'POST/work_overtime/report/users': (params: any, extra?: Extra) => {
    return axios(`/work_overtime/report/users`, preParams(params, extra, 'post'))
  },

  /**
   * @description: pm部门数据报表
   * @param {string} yearDate
   * @return {array}
   */
  'POST/pm_report/report': (params: any, extra?: Extra) => {
    return axios(`/pm_report/report`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 诉退款详情
   * @param {array} yearDate
   * @return {array}
   */
  'POST/pm_report/complaint': (params: any, extra?: Extra) => {
    return axios(`/pm_report/complaint`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取pm团队权限列表
   * @param {array} yearDate
   * @return {array}
   */
  'GET/report_pm_teams/list': () => {
    return axios.get(`/report_pm_teams/list`)
  },

  /**
   * @description: 获取pm团队权限列表
   * @param {array} yearDate
   * @return {array}
   */
  'GET/report_pm_teams/enable': ({id, enable}: any) => {
    return axios.get(`/report_pm_teams/enable?id=${id}&enable=${enable}`)
  },

  /**
   * @description: 获取团长
   * @return {array}
   */
  'GET/report_pm_teams/owner': () => {
    return axios.get(`/report_pm_teams/owner`)
  },

  /**
   * @description: 获取团员
   * @return {array}
   */
  'GET/report_pm_teams/member': (params: {id: number}) => {
    return axios.get(`/report_pm_teams/member`, preParams(params, {}, 'get'))
  },

  /**
   * @description: 子团队下拉框
   * @return {array}
   */
  'GET/report_pm_teams/drop_list': () => axios.get(`/report_pm_teams/drop_list`),

  /**
   * @description: 创建子团队
   * @return {array}
   */
  'POST/report_pm_teams': (params: any, extra?: Extra) => {
    return axios(`/report_pm_teams`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 编辑子团队
   * @return {array}
   */
  'PATCH/report_pm_teams': (params: any, extra?: Extra) => {
    return axios(`/report_pm_teams`, preParams(params, extra, 'patch'))
  },
  /**
   * @description: 销售业绩_报表
   * @return {array}
   */
  'POST/sales_performance/report': (params: any, extra?: Extra) => {
    return axios(`/sales_performance/report`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 销售工龄_修正保存
   * @return {array}
   */
  'POST/correction_seniority/save': (params: any, extra?: Extra) => {
    return axios(`/correction_seniority/save`, preParams(params, extra, 'post'))
  },
  /**
   * @description: PM业绩看板
   * @return {array}
   */
  'POST/pm_report/pm_achievement': (params: any, extra?: Extra) => {
    return axios(`/pm_report/pm_achievement`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 列表-PM所属团队
   * @return {array}
   */
  'POST/report_pm_org/page': (params: any, extra?: Extra) => {
    return axios(`/report_pm_org/page`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 新增-PM所属团队
   * @return {array}
   */
  'POST/report_pm_org': (params: any, extra?: Extra) => {
    return axios(`/report_pm_org`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 更新-PM所属团队
   * @return {array}
   */
  'PATCH/report_pm_org': (params: any, extra?: Extra) => {
    return axios(`/report_pm_org`, preParams(params, extra, 'patch'))
  },
  /**
   * @description: 删除-PM所属团队
   * @return {array}
   */
  'DELETE/report_pm_org/:id': (params: any, extra?: Extra) => {
    return axios(`/report_pm_org/${params.id}`, preParams(params, extra, 'delete'))
  },
  /**
   * @description: PM业绩看板穿透
   * @return {array}
   */
  'POST/pm_report/download_achievement_detail': (params: any, extra?: Extra) => {
    return axios(`/pm_report/download_achievement_detail`, preParams(params, extra, 'post'))
  },
  /**
   * @description: PM业绩看板穿透页面
   * @return {array}
   */
  'POST/pm_report/achievement_fee_page': (params: any, extra?: Extra) => {
    return axios(`/pm_report/achievement_fee_page`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 预算维护_根据团队批量生成
   * @return {array}
   */
  'POST/budget/batch/generate': (params: any, extra?: Extra) => {
    return axios(`/budget/batch/generate`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 预算维护_批量删除
   * @return {array}
   */
  'DELETE/budget/batch': (params: any, extra?: Extra) => {
    return axios(`/budget/batch`, preParams(params, extra, 'delete'))
  },
  /**
   * @description: 译员分摊表
   * @return {array}
   */
  'POST/pm_report/translator_allocation': (params: any, extra?: Extra) => {
    return axios(`/pm_report/translator_allocation`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 译员分摊表导出
   * @return {array}
   */
  'POST/pm_report/translator_allocation_export': (params: any, extra?: Extra) => {
    return axios(`/pm_report/translator_allocation_export`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 项目数据统计_报表
   * @return {array}
   */
  'POST/iso/project_data_statistic/report': (params: any, extra?: Extra) => {
    return axios(`/iso/project_data_statistic/report`, preParams(params, extra, 'post'))
  },
}
  
