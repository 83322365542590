import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
import {
  User,
  Forget,
  UserSearch,
  financialSearch,
  CreatePlan
} from './model/hrModel';

export default {
  /**
   * @description: 新增员工
   * @param {string} 
   * @return {void}
   */
  'POST/users': (params: User, extra?: Extra) => {
    return axios(`/users`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 批量上传新员工
   * @param {file} file 员工文件
   * @return {void}
   */
  'POST/users/upload': (params: {file: File}, extra?: Extra) => {
    return axios(`/users/upload`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 根据username获取员工信息
   * @param {string} username 用户登录名
   * @return {void}
   */
  'GET/users/username': (params: {username: string}, extra?: Extra) => {
    return axios.get(`/users/username`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 获取jwtc创建的token
   * @return {void}
   */
  'GET/jwt/token': (params: any, extra?: Extra) => {
    return axios.get<{token: string}>(`/jwt/token`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 找回密码(重置密码)
   * @return {void}
   */
  'PATCH/users/forget_pwd': (params: Forget, extra?: Extra) => {
    return axios(`/users/forget_pwd`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 根据条件查询人员信息(员工列表)
   * @return {void}
   */
  'POST/users/profile/list': (params: UserSearch, extra?: Extra) => {
    return axios(`/users/profile/list`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 判断邮箱是否重复
   * @param {string} email 邮箱
   * @return {void}
   */
  'GET/users/check_email': (params: {email: string}, extra?: Extra) => {
    return axios.get<{exists: boolean}>(`/users/check_email`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 判断登录名是否存在
   * @param {string} username 用户名
   * @return {void}
   */
  'GET/users/check_name': (params: {username: string}, extra?: Extra) => {
    return axios.get<{exists: boolean}>(`/users/check_name`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 员工其他信息
   * @return {void}
   */
  'PATCH/users/profile': (params: any, extra?: Extra) => {
    return axios(`/users/profile`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 员工信息(导出所有员工exlce)
   * @param {Array} keys 导出的表头
   * @return {void}
   */
  'POST/users/export': (params: {keys: string[]}, extra?: Extra) => {
    return axios(`/users/export`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 员工岗位变动信息查询
   * @param {Number} id 导出的表头
   * @return {void}
   */
  'GET/job_changes/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.get<any>(`/job_changes/${params.id}`)
  },

  /**
   * @description: 删除员工岗位信息
   * @param {Number} id
   * @return {void}
   */
  'DELETE/job_changes/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.delete<any>(`/job_changes/${params.id}`)
  },

  /**
   * @description: 删除员工资产信息
   * @param {Number} id
   * @return {void}
   */
  'DELETE/assets/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.delete<any>(`/assets/${params.id}`)
  },

  /**
   * @description: 员工列表下拉
   * @return {void}
   */
  'GET/users/list': (params?: any, extra?: Extra) => {
    return axios.get<any>(`/users/list`)
  },

  /**
   * @description: 重置密码
   * @param {number} userId 用户ID
   * @return {void}
   */
  'GET/users/reset_pwd/{userId}': (params: {userId: number}, extra?: Extra) => {
    return axios.get<any>(`/users/reset_pwd/${params.userId}`)
  },

  /**
   * @description: 员工个人密码修改
   * @param {number} userId 用户ID
   * @return {void}
   */
  'PATCH/users/update_pwd': (params: any, extra?: Extra) => {
    return axios(`/users/update_pwd`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 检查未完善信息的员工
   * @return {void}
   */
  'GET/users/profile': (params?: any, extra?: Extra) => {
    return axios.get<any[]>(`/users/profile`)
  },

  /**
   * @description: 获取员工办公所在地
   * @return {void}
   */
  'GET/house_leases': (params?: any, extra?: Extra) => {
    return axios.get<any[]>(`/house_leases`)
  },

  /**
   * @description: 校验身份证号码是否已存在系统
   * @return {void}
   */
  'GET/users/check_card': (params: {cardId: string}, extra?: Extra) => {
    return axios.get<any[]>(`/users/check_card`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 根据身份证,获取出生年月，年龄，性别，户籍所在地
   * @param {string} cardId 证件号码
   * @param {string} cardType 证件类型
   * @return {void}
   */
  'GET/users/card_id': (params: {cardId: string, cardType: string}, extra?: Extra) => {
    return axios.get<any[]>(`/users/card_id`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 新增用户财务岗位
   * @param {number} userId 用户ID
   * @param {string} date 日期
   * @param {number} financeStationId 财务岗位ID
   * @return {void}
   */
  'POST/user_finance_stations': (params: {userId: number, date: string, financeStationId: number}, extra?: Extra) => {
    return axios(`/user_finance_stations`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 更新用户财务岗位
   * @param {number} userId 用户ID
   * @param {string} date 日期
   * @param {number} financeStationId 财务岗位ID
   * @return {void}
   */
  'PATCH/user_finance_stations': (params: {userId: number, date: string, financeStationId: number}, extra?: Extra) => {
    return axios(`/user_finance_stations`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 删除用户财务岗位
   * @param {number} id 用户ID
   * @return {void}
   */
  'DELETE/user_finance_stations/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.delete<void>(`/user_finance_stations/${params.id}`)
  },

  /**
   * @description: 获取-用户财务岗位列表
   * @return {void}
   */
  'POST/user_finance_stations/list': (params: financialSearch, extra?: Extra) => {
    return axios(`/user_finance_stations/list`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取财务岗位下拉
   * @return {void}
   */
  'GET/user_finances': (params?: any, extra?: Extra) => {
    return axios.get<any[]>(`/user_finances`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 新增财务岗位
   * @param {string} name 财务岗位名称
   * @param {string} expenseType 财务岗位类型
   * @return {void}
   */
  'POST/user_finances': (params?: {name: string, expenseType?: string}, extra?: Extra) => {
    return axios(`/user_finances`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 删除财务岗位
   * @param {number} name 财务岗位ID
   * @return {void}
   */
  'DELETE/user_finances/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.delete<void>(`/user_finances/${params.id}`)
  },

  /**
   * @description: 更新财务岗位
   * @param {number} name 财务岗位ID
   * @param {string} expenseType 财务岗位ID
   * @param {string} name 财务岗位名称
   * @return {void}
   */
  'PATCH/user_finances': (params: {id: number, name: string, expenseType?: string}, extra?: Extra) => {
    return axios(`/user_finances`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 批量修改财务岗位
   * @param {array} ids 员工list
   * @param {number} financeStationId 财务岗位ID
   * @return {void}
   */
  'PATCH/user_finance_stations/batch': (params: {ids: number[], financeStationId: number}, extra?: Extra) => {
    return axios(`/user_finance_stations/batch`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 员工基本信息保存
   * @return {void}
   */
  'POST/users/profile': (params: any, extra?: Extra) => {
    return axios(`/users/profile`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取员工基本信息保存
   * @return {void}
   */
  'GET/users/{id}/profile': (params: {id: number}, extra?: Extra) => {
    return axios.get<any>(`/users/${params.id}/profile`)
  },

  /**
   * @description: 员工资产信息保存
   * @return {void}
   */
  'PATCH/assets': (params: any, extra?: Extra) => {
    return axios(`/assets`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 删除员工工作经历
   * @param {number} userId 员工ID
   * @param {number} id
   * @return {void}
   */
  'DELETE/users/{userId}/word_experiences/{id}': (params: {userId: number, id: number}, extra?: Extra) => {
    return axios.delete<any>(`/users/${params.userId}/word_experiences/${params.id}`)
  },

  /**
   * @description: 保存员工作经历
   * @return {void}
   */
  'POST/users/{uId}/word_experiences': (params: {uId: number}, extra?: Extra) => {
    return axios(`/users/${params.uId}/word_experiences`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取员工工作经历
   * @param {number} userId 员工ID
   * @return {void}
   */
  'GET/users/{userId}/word_experiences': (params: {userId: number}, extra?: Extra) => {
    return axios.get<any>(`/users/${params.userId}/word_experiences`)
  },

  /**
   * @description: 获取员工资产信息
   * @param {number} userId 员工ID
   * @return {void}
   */
  'GET/assets/{userId}': (params: {userId: number}, extra?: Extra) => {
    return axios.get<any>(`/assets/${params.userId}`)
  },

  /**
   * @description: 员工资产信息保存
   * @return {void}
   */
  'POST/assets': (params: any, extra?: Extra) => {
    return axios(`/assets`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 根据id查询社保卡信息
   * @param {number} userId 员工ID
   * @return {void}
   */
  'GET/socials/{userId}': (params: {userId: number}, extra?: Extra) => {
    return axios.get<any>(`/socials/${params.userId}`)
  },

  /**
   * @description: 员工社保信息保存
   * @return {void}
   */
  'POST/socials': (params: any, extra?: Extra) => {
    return axios(`/socials`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 根据员工英文名或身份证号码查重
   * @param {string} key 姓名
   * @param {string} cardId 证件号码
   * @return {void}
   */
  'GET/users/repeat': (params: {key: string, cardId: string}, extra?: Extra) => {
    return axios.get(`/users/repeat`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 获取工位使用情况
   * @param {string} key 所在地
   * @param {number} leaseId 办公所在地ID
   * @param {string} date 日期
   * @param {number} page 页数
   * @param {number} size 条数
   * @return {void}
   */
  'POST/users/seat': (params: {key: string, leaseId: string, date: string}, extra?: Extra) => {
    return axios(`/users/seat`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 未维护离职员工
   * @return {void}
   */
  'GET/users/profile/quit': (params?: any, extra?: Extra) => {
    return axios.get<any>(`/users/profile/quit`)
  },

  /**
   * @description: 财务成本列表
   * @return {void}
   */
  'POST/users/salary_details/page/finance': (params?: any, extra?: Extra) => {
    return axios(`/users/salary_details/page/finance`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 批量修改员工信息
   * @return {void}
   */
  'PATCH/users/batch_update': (params: {file: File}, extra?: Extra) => {
    return axios(`/users/batch_update`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 下载批量修改员工信息模板
   * @return {void}
   */
  'GET/users/info_template': (params: {file: File}, extra?: Extra) => {
    return axios.get<void>(`/users/info_template`, preParams(params, extra, 'get'))
  },

  /**
   * @description: 获取材料(入职资料)
   * @param {number} userId 员工ID
   * @return {void}
   */
  'GET/user/material/{userId}': (params: {userId: number}, extra?: Extra) => {
    return axios.get<void>(`/user/material/${params.userId}`)
  },

  /**
   * @description: 上传用户入职资料
   * @param {number} userId 员工ID
   * @param {string} type 资料类型
   * @param {files} files 入职资料文件
   * @return {void}
   */
  'POST/user/material/upload': (params: {userId: number, files: File, type: string}, extra?: Extra) => {
    return axios(`/user/material/upload`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 添加证件自定义类别
   * @param {number} userId 员工ID
   * @param {string} category 自定义类别
   * @return {void}
   */
  'POST/user/material/category': (params: {userId: number, category: string}, extra?: Extra) => {
    return axios(`/user/material/category`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 上传自定义证件
   * @param {number} userId 员工ID
   * @return {void}
   */
  'POST/user/material/upload/custom': (params: {userId: number, category: string}, extra?: Extra) => {
    return axios(`/user/material/upload/custom`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 员工入职
   * @return {void}
   */
  'POST/workbench/staff_entry': (params: any, extra?: Extra) => {
    return axios(`/workbench/staff_entry`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 员工转正
   * @return {void}
   */
  'POST/workbench/staff_regular': (params: any, extra?: Extra) => {
    return axios(`/workbench/staff_regular`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 员工调薪
   * @return {void}
   */
  'POST/workbench/staff_salary_adjust': (params: any, extra?: Extra) => {
    return axios(`/workbench/staff_salary_adjust`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 员工离职
   * @return {void}
   */
  'POST/workbench/staff_quit': (params: any, extra?: Extra) => {
    return axios(`/workbench/staff_quit`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 员工变动记录条件查询
   * @return {void}
   */
  'POST/workbench/staff_change/conditions': (params: any, extra?: Extra) => {
    return axios(`/workbench/staff_change/conditions`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 员工转岗
   * @return {void}
   */
  'POST/workbench/staff_transfer': (params: any, extra?: Extra) => {
    return axios(`/workbench/staff_transfer`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取员工变动记录
   * @return {void}
   */
  'POST/user_change_records': (params: any, extra?: Extra) => {
    return axios(`/user_change_records`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 获取员工变动记录
   * @return {void}
   */
  'PATCH/user_change_records/handle': (params: any, extra?: Extra) => {
    return axios(`/user_change_records/handle`, preParams(params, extra, 'patch'))
  },

  /**
   * @description: 获取员工变动记录
   * @return {void}
   */
  'POST/labor_contracts/exception/list': (params: any, extra?: Extra) => {
    return axios(`/labor_contracts/exception/list`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 删除异常信息
   * @param {number} id 异常信息ID
   * @return {void}
   */
  'DELETE/labor_contracts/exception/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.delete<void>(`/labor_contracts/exception/${params.id}`)
  },

  /**
   * @description: 获取有权限查看的异常个数
   * @param {number} id 异常信息ID
   * @return {number} count 异常数量
   */
  'GET/labor_contracts/exception/count': (params?: any, extra?: Extra) => {
    return axios.get<{count: number}>(`/labor_contracts/exception/count`)
  },
   /**
   * @description: 员工信息_修改记录
   * @return {void}
   */
   'POST/users/modify/record/query': (params: any, extra?: Extra) => {
    return axios(`/users/modify/record/query`, preParams(params, extra, 'post'))
  },
  /**
   * @description: 获取某个角色，被多少人使用
   */
  'GET/roles/:id/use': (params?: any, extra?: Extra) => {
    return axios(`/roles/${params.id}/use`, preParams({}, extra, 'get'))
  },
  /**
   * @description: 移除用户对应的角色
   */
  'DELETE/role_user/user': (params?: any, extra?: Extra) => {
    return axios(`/role_user/user?role_id=${params.role_id}&user_id=${params.user_id}`, preParams({}, extra, 'delete'))
  },

  /**
   * @description: 培训计划列表
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/employee_training/plans': (params: {page: number, size: number}, extra?: Extra) => {
    return axios(`/employee_training/plans`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 创建培训计划
   * @param {string} name - 计划名称
   * @param {string} start - 开始时间
   * @param {string} end - 结束时间
   * @param {string} studyUrl - 内训地址
   * @param {string} type - 培训类型: "NEW_EMPLOYEE" (新员工), "ORGANIZATION" (团队), "FINANCE_STATION" (财务岗位)
   * @param {object} typeValue.ids - 类型关联的 ID 数组，例如 [1, 3, 5]
   */
  'POST/employee_training/plan': (params: CreatePlan, extra?: Extra) => {
    return axios(`/employee_training/plan`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 培训计划学员进度
   * @param {number} id - 培训计划ID
   * @param {Boolean} completed - 是否完成
   * @param {number} page
   * @param {number} size
   * @return {void}
   */
  'POST/employee_training/plan/users': (params: {id: number, completed: boolean, page: number, size: number}, extra?: Extra) => {
    return axios(`/employee_training/plan/users`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 手动触发同步单个培训计划
   * @param {number} id - 培训计划ID
   * @return {void}
   */
  'POST/employee_training/plan/{id}/sync': (params: {id: number}, extra?: Extra) => {
    return axios(`/employee_training/plan/${params.id}/sync`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 推送培训计划通知
   * @param {number} id - 培训计划ID
   * @return {void}
   */
  'POST/employee_training/plan/{id}/notify': (params: {id: number}, extra?: Extra) => {
    return axios(`/employee_training/plan/${params.id}/notify`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 单个用户推送通知
   * @param {number} id - 培训计划ID
   * @param {number} userId - 用户ID
   * @return {void}
   */
  'POST/employee_training/plan/{id}/notify/{userId}': (params: {id: number, userId: number}, extra?: Extra) => {
    return axios(`/employee_training/plan/${params.id}/notify/${params.userId}`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 终止计划
   * @param {number} id - 培训计划ID
   * @return {void}
   */
  'POST/employee_training/plan/{id}/stop': (params: {id: number}, extra?: Extra) => {
    return axios(`/employee_training/plan/${params.id}/stop`, preParams(params, extra, 'post'))
  },
}