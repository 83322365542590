import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
    /**
     * @description: PM个人数据分析
     * @return {void}
     */
    'POST/delivery/pm_achievement': (params: any, extra?: Extra) => {
        return axios(`/delivery/pm_achievement`, preParams(params, extra, 'post'))
    },
    /**
     * @description: PM部门管理数据看板
     * @return {void}
     */
    'POST/delivery/pm_department_efficiency': (params: any, extra?: Extra) => {
        return axios(`/delivery/pm_department_efficiency`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 列表-PM业绩目标
     * @return {void}
     */
    'POST/delivery_target_achievements/page': (params: any, extra?: Extra) => {
        return axios(`/delivery_target_achievements/page`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 分配-PM业绩目标
     * @return {void}
     */
    'POST/delivery_target_achievements': (params: any, extra?: Extra) => {
        return axios(`/delivery_target_achievements`, preParams(params, extra, 'post'))
    },
    /**
     * @description: 更新-PM业绩目标
     * @return {void}
     */
    'PATCH/delivery_target_achievements': (params: any, extra?: Extra) => {
        return axios(`/delivery_target_achievements`, preParams(params, extra, 'patch'))
    },
    /**
     * @description: 删除-PM业绩目标
     * @return {void}
     */
    'DELETE/delivery_target_achievements/Id': (params: any, extra?: Extra) => {
        return axios(`/delivery_target_achievements/${params.id}`, preParams({}, extra, 'delete'))
    },
}