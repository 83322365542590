// 财务收款模块
import axios from '@/http/index'
import {preParams, Extra, BasicTableResult} from "../index";
// import {} from './model/individualModel';

export default {
  /**
   * @description: 预付款分页查询
   * @return {void}
   */
  'POST/cost_cash_packs/advance/page': (params: any, extra?: Extra) => {
    return axios(`/cost_cash_packs/advance/page`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 预付款更新
   * @param {number} id
   * @param {number} notes
   * @return {void}
   */
  'POST/cost_cash_packs/advance/update': (params: any, extra?: Extra) => {
    return axios(`/cost_cash_packs/advance/update`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 预付款新增
   * @param {number} resourceId
   * @param {number} taskId
   * @return {void}
   */
  'POST/cost_cash_packs/advance/add': (params: any, extra?: Extra) => {
    return axios(`/cost_cash_packs/advance/add`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 预付款删除
   * @param {number} id
   * @return {void}
   */
  'DELETE/cost_cash_packs/advance/{id}': (params: {id: number}) => {
    return axios.delete<void>(`/cost_cash_packs/advance/${params.id}`)
  },
  
  /**
   * @description: 生成凭证记录
   * @param {number} id
   * @return {void}
  */
  'POST/cost_cash_packs/detail/generate_voucher': (params: any, extra?: Extra) => {
    return axios(`/cost_cash_packs/detail/generate_voucher`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 流水匹配详情列表
   * @return {void}
  */
  'POST/flow_pack_details/match_detail': (params: any, extra?: Extra) => {
    return axios(`/flow_pack_details/match_detail`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 重算PaynoeerFee
   * @param {number} id
   * @return {void}
  */
  'POST/cost_cash_packs/detail/compute_fee': (params: {id: number}, extra?: Extra) => {
    return axios(`/cost_cash_packs/detail/compute_fee`, preParams(params, extra, 'post'))
  },
  
  /**
   * @description: 错误提现分页查询
   * @return {void}
  */
  'POST/convert_to_cash/error_cash_page': (params: any, extra?: Extra) => {
    return axios(`/convert_to_cash/error_cash_page`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 忽略错误提现
   * @param {number} id
   * @return {void}
  */
  'POST/convert_to_cash/error_cash/{id}': (params: {id: number}, extra?: Extra) => {
    return axios.delete<void>(`/convert_to_cash/error_cash/${params.id}`)
  },

  /**
   * @description: 提现包详情 驳回
   * @param {number} id
   * @param {string} note
   * @return {void}
  */
  'POST/cost_cash_packs/detail/reject': (params: {id: number, note: string}, extra?: Extra) => {
    return axios(`/cost_cash_packs/detail/reject`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 按照提现生成-提现流水
   * @param {array} ids
   * @return {void}
  */
  'POST/cost_cash_flows/multi_choice_cash': (params: {ids: number[]}, extra?: Extra) => {
    return axios(`/cost_cash_flows/multi_choice_cash`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 提现包 译员状态检测
   * @param {array} ids
   * @param {number} packId 支付包Id
   * @return {void}
  */
  'POST/cost_cash_packs/detail/check_translator_status': (params: {ids: number[], packId: number}, extra?: Extra) => {
    return axios(`/cost_cash_packs/detail/check_translator_status`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 火星对账单查询
   * @param {string} name
   * @param {string} startTime
   * @param {string} endTime
   * @param {string} createBy
   * @param {string} status
   * @param {number} page
   * @param {number} size
   * @return {void}
  */
 
  'POST/mars_statement/query': (params: {name: string, startTime: string, endTime: string, createBy: string, status: string, page: number, size: number}, extra?: Extra) => {
    return axios(`/mars_statement/query`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 火星对账单创建
   * @param {string} name
   * @return {void}
  */
  'POST/mars_statement/create_plan': (params: {name: string}, extra?: Extra) => {
    return axios(`/mars_statement/create_plan`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 火星对账单删除
   * @param {number} id
   * @return {void}
  */
  'DELETE/mars_statement/mark/{ID}': ({id}: {id: number}) => {
    return axios.delete<void>(`/mars_statement/mark/${id}`)
  },

  /**
   * @description: 火星对账单下载
   * @param {number} id
   * @return {void}
  */
  'POST/mars_statement/download': (params: {id: number}, extra?: Extra) => {
    return axios(`/mars_statement/download`, preParams(params, extra, 'post'))
  },

  /**
   * @description: 火星对账单下拉列表
   * @return {void}
  */
  'GET/mars_statement/drop_list': () => axios.get(`/mars_statement/drop_list`),
}